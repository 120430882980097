import dayjs from "dayjs";
import { ISuUser } from "app/shared/model/su-user.model";
import { kpitype } from "app/shared/model/enumerations/kpitype.model";
import { auditperiod } from "app/shared/model/enumerations/auditperiod.model";

export interface IPrvnKpi {
  id?: number;
  name?: string;
  description?: string | null;
  isactive?: number;
  kpi_type?: kpitype;
  level?: number;
  kpi_order?: number | null;
  audit_period?: auditperiod;
  parent_id?: number | null;
  max_score?: number;
  created_date?: string | null;
  modified_date?: string | null;
  createdBy?: ISuUser;
  modifiedBy?: ISuUser;
}

export const defaultValue: Readonly<IPrvnKpi> = {};
