import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { Translate, TextFormat } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./project-member-client-roles.reducer";

export const ProjectMemberClientRolesDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const projectMemberClientRolesEntity = useAppSelector(
    (state) => state.projectMemberClientRoles.entity
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="projectMemberClientRolesDetailsHeading">
          <Translate contentKey="CorpvineApp.projectMemberClientRoles.detail.title">
            ProjectMemberClientRoles
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{projectMemberClientRolesEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="CorpvineApp.projectMemberClientRoles.name">
                Name
              </Translate>
            </span>
          </dt>
          <dd>{projectMemberClientRolesEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="CorpvineApp.projectMemberClientRoles.description">
                Description
              </Translate>
            </span>
          </dt>
          <dd>{projectMemberClientRolesEntity.description}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="CorpvineApp.projectMemberClientRoles.active">
                Active
              </Translate>
            </span>
          </dt>
          <dd>{projectMemberClientRolesEntity.active}</dd>
          <dt>
            <Translate contentKey="CorpvineApp.projectMemberClientRoles.created_by">
              Created By
            </Translate>
          </dt>
          <dd>
            {projectMemberClientRolesEntity.createdBy
              ? projectMemberClientRolesEntity.createdBy
              : ""}
          </dd>
          <dt>
            <span id="created_at">
              <Translate contentKey="CorpvineApp.projectMemberClientRoles.created_at">
                Created At
              </Translate>
            </span>
          </dt>
          <dd>
            {projectMemberClientRolesEntity.created_at ? (
              <TextFormat
                value={projectMemberClientRolesEntity.created_at}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.projectMemberClientRoles.modified_by">
              Modified By
            </Translate>
          </dt>
          <dd>
            {projectMemberClientRolesEntity.modifiedBy
              ? projectMemberClientRolesEntity.modifiedBy
              : ""}
          </dd>
          <dt>
            <span id="updated_at">
              <Translate contentKey="CorpvineApp.projectMemberClientRoles.updated_at">
                Updated At
              </Translate>
            </span>
          </dt>
          <dd>
            {projectMemberClientRolesEntity.updated_at ? (
              <TextFormat
                value={projectMemberClientRolesEntity.updated_at}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
       </dl>
        <Button
          tag={Link}
          to="/project-member-client-roles"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/project-member-client-roles/${projectMemberClientRolesEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProjectMemberClientRolesDetail;
