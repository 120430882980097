import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { TextFormat, Translate } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./organisation.reducer";

export const OrganisationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const organisationEntity = useAppSelector(
    (state) => state.organisation.entity,
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="organisationDetailsHeading">
          <Translate contentKey="CorpvineApp.organisation.detail.title">
            Organisation
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{organisationEntity.id}</dd>
          <dt>
            <span id="org_code">
              <Translate contentKey="CorpvineApp.organisation.org_code">
                Org Code
              </Translate>
            </span>
          </dt>
          <dd>{organisationEntity.org_code}</dd>
          <dt>
            <span id="org_name">
              <Translate contentKey="CorpvineApp.organisation.org_name">
                Org Name
              </Translate>
            </span>
          </dt>
          <dd>{organisationEntity.org_name}</dd>
          <dt>
            <span id="org_description">
              <Translate contentKey="CorpvineApp.organisation.org_description">
                Org Description
              </Translate>
            </span>
          </dt>
          <dd>{organisationEntity.org_description}</dd>
          <dt>
            <span id="is_active">
              <Translate contentKey="CorpvineApp.organisation.is_active">
                Is Active
              </Translate>
            </span>
          </dt>
          <dd>{organisationEntity.is_active}</dd>
          <dt>
              <Translate contentKey="CorpvineApp.organisation.created_by">
                Created By
              </Translate>
          </dt>
          <dd>
            {organisationEntity.createdBy? organisationEntity.createdBy
                : "" }
          </dd>
          <dt>
                      <span id="created_date">
                        <Translate contentKey="CorpvineApp.organisation.created_date">
                          Created Date
                        </Translate>
                      </span>
                    </dt>
                    <dd>
                      {organisationEntity.created_date ? (
                        <TextFormat
                          value={organisationEntity.created_date}
                          type="date"
                          format={APP_DATE_FORMAT}
                        />
                      ) : null}
                    </dd>
          <dt>
              <Translate contentKey="CorpvineApp.organisation.modified_by">
                Modified By
              </Translate>
          </dt>
          <dd>{organisationEntity.modifiedBy?organisationEntity.modifiedBy:"" }</dd>

          <dt>
            <span id="modified_date">
              <Translate contentKey="CorpvineApp.organisation.modified_date">
                Modified Date
              </Translate>
            </span>
          </dt>
          <dd>
            {organisationEntity.modified_date ? (
              <TextFormat
                value={organisationEntity.modified_date}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
        </dl>
        <Button
          tag={Link}
          to="/organisation"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/organisation/${organisationEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default OrganisationDetail;
