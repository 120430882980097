import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import {
  Translate,
  TextFormat,
  getSortState,
  JhiPagination,
  JhiItemCount,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import {
  ASC,
  DESC,
  ITEMS_PER_PAGE,
  SORT,
} from "app/shared/util/pagination.constants";
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { IProjectMemberStatuses } from "app/shared/model/project-member-statuses.model";
import { getEntities } from "./project-member-statuses.reducer";

export const ProjectMemberStatuses = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      getSortState(location, ITEMS_PER_PAGE, "id"),
      location.search
    )
  );

  const projectMemberStatusesList = useAppSelector(
    (state) => state.projectMemberStatuses.entities
  );
  const loading = useAppSelector(
    (state) => state.projectMemberStatuses.loading
  );
  const totalItems = useAppSelector(
    (state) => state.projectMemberStatuses.totalItems
  );

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(",");
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = (p) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = (currentPage) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2
        id="project-member-statuses-heading"
        data-cy="ProjectMemberStatusesHeading"
      >
        <Translate contentKey="CorpvineApp.projectMemberStatuses.home.title">
          Project Member Statuses
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{" "}
            <Translate contentKey="CorpvineApp.projectMemberStatuses.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/project-member-statuses/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="CorpvineApp.projectMemberStatuses.home.createLabel">
              Create new Project Member Statuses
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {projectMemberStatusesList && projectMemberStatusesList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort("id")}>
                  <Translate contentKey="CorpvineApp.projectMemberStatuses.id">
                    ID
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("name")}>
                  <Translate contentKey="CorpvineApp.projectMemberStatuses.name">
                    Name
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("description")}>
                  <Translate contentKey="CorpvineApp.projectMemberStatuses.description">
                    Description
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("active")}>
                  <Translate contentKey="CorpvineApp.projectMemberStatuses.active">
                    Active
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.projectMemberStatuses.created_by">
                    Created By
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th >
                  <Translate contentKey="CorpvineApp.projectMemberStatuses.created_at">
                    Created At
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.projectMemberStatuses.modified_by">
                    Modified By
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th >
                  <Translate contentKey="CorpvineApp.projectMemberStatuses.updated_at">
                    Updated At
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {projectMemberStatusesList.map((projectMemberStatuses, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/project-member-statuses/${projectMemberStatuses.id}`}
                      color="link"
                      size="sm"
                    >
                      {projectMemberStatuses.id}
                    </Button>
                  </td>
                  <td>{projectMemberStatuses.name}</td>
                  <td>{projectMemberStatuses.description}</td>
                  <td>{projectMemberStatuses.active}</td>
                  <td>
                    {projectMemberStatuses.createdBy ? (projectMemberStatuses.createdBy) : (
                      ""
                    )}
                  </td>
                  <td>
                    {projectMemberStatuses.created_at ? (
                      <TextFormat
                        type="date"
                        value={projectMemberStatuses.created_at}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td>
                    {projectMemberStatuses.modifiedBy ? (projectMemberStatuses.modifiedBy) : (
                      ""
                    )}
                  </td>
                  <td>
                    {projectMemberStatuses.updated_at ? (
                      <TextFormat
                        type="date"
                        value={projectMemberStatuses.updated_at}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/project-member-statuses/${projectMemberStatuses.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/project-member-statuses/${projectMemberStatuses.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/project-member-statuses/${projectMemberStatuses.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="CorpvineApp.projectMemberStatuses.home.notFound">
                No Project Member Statuses found
              </Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div
          className={
            projectMemberStatusesList && projectMemberStatusesList.length > 0
              ? ""
              : "d-none"
          }
        >
          <div className="justify-content-center d-flex">
            <JhiItemCount
              page={paginationState.activePage}
              total={totalItems}
              itemsPerPage={paginationState.itemsPerPage}
              i18nEnabled
            />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProjectMemberStatuses;
