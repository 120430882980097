import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";
import ProjectMemberStatuses from "./project-member-statuses";
import SkillCategory from "./skill-category";
import Skill from "./skill";
import CareerGrowthGoal from "./career-growth-goal";
import SkillCategorySkillLink from "./skill-category-skill-link";
import TitlePositionLink from "./title-position-link";
import MainJobTitles from "./main-job-titles";
import MainPositions from "./main-positions";
import PrvnKpi from "./prvn-kpi";
import ProjectMemberSysvineRoles from "./project-member-sysvine-roles";
import ProjectMemberClientRoles from "./project-member-client-roles";
import ProjectMemberCostTypes from "./project-member-cost-types";
import Location from "./location";
import Category from "./category";
import Source from "./source";
import Review from "./review";
import ApplicationDomainConfig from "./application-domain-config";
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="prvn-kpi/*" element={<PrvnKpi />} />
        <Route path="skill-category/*" element={<SkillCategory />} />
        <Route path="skill/*" element={<Skill />} />
        <Route path="career-growth-goal/*" element={<CareerGrowthGoal />} />
        <Route
          path="skill-category-skill-link/*"
          element={<SkillCategorySkillLink />}
        />
        <Route path="title-position-link/*" element={<TitlePositionLink />} />
        <Route path="main-job-titles/*" element={<MainJobTitles />} />
        <Route path="main-positions/*" element={<MainPositions />} />
        <Route
          path="project-member-sysvine-roles/*"
          element={<ProjectMemberSysvineRoles />}
        />
        <Route
          path="project-member-client-roles/*"
          element={<ProjectMemberClientRoles />}
        />
        <Route
          path="project-member-cost-types/*"
          element={<ProjectMemberCostTypes />}
        />
        <Route
          path="project-member-statuses/*"
          element={<ProjectMemberStatuses />}
        />
        <Route path="location/*" element={<Location />} />
        <Route path="category/*" element={<Category />} />
        <Route path="source/*" element={<Source />} />
        <Route path="review/*" element={<Review />} />
        <Route
          path="application-domain-config/*"
          element={<ApplicationDomainConfig />}
        />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
