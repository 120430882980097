import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { Translate, getSortState } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntities } from "./application-domain-config.reducer";

export const ApplicationDomainConfig = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(
    overridePaginationStateWithQueryParams(
      getSortState(pageLocation,ITEMS_PER_PAGE, "id"),
      pageLocation.search,
    ),
  );

  const applicationDomainConfigList = useAppSelector(
    (state) => state.applicationDomainConfig.entities,
  );
  const loading = useAppSelector(
    (state) => state.applicationDomainConfig.loading,
  );

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = (p) => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2
        id="application-domain-config-heading"
        data-cy="ApplicationDomainConfigHeading"
      >
        <Translate contentKey="CorpvineApp.applicationDomainConfig.home.title">
          Application Domain Configs
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{" "}
            <Translate contentKey="CorpvineApp.applicationDomainConfig.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/application-domain-config/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="CorpvineApp.applicationDomainConfig.home.createLabel">
              Create new Application Domain Config
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {applicationDomainConfigList &&
        applicationDomainConfigList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort("id")}>
                  <Translate contentKey="CorpvineApp.applicationDomainConfig.id">
                    ID
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("id")} />
                </th>
                <th className="hand" onClick={sort("config_key")}>
                  <Translate contentKey="CorpvineApp.applicationDomainConfig.config_key">
                    Config Key
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("config_key")}
                  />
                </th>
                <th className="hand" onClick={sort("config_value")}>
                  <Translate contentKey="CorpvineApp.applicationDomainConfig.config_value">
                    Config Value
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("config_value")}
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {applicationDomainConfigList.map((applicationDomainConfig, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/application-domain-config/${applicationDomainConfig.id}`}
                      color="link"
                      size="sm"
                    >
                      {applicationDomainConfig.id}
                    </Button>
                  </td>
                  <td>{applicationDomainConfig.config_key}</td>
                  <td>{applicationDomainConfig.config_value}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/application-domain-config/${applicationDomainConfig.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/application-domain-config/${applicationDomainConfig.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/application-domain-config/${applicationDomainConfig.id}/delete`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="CorpvineApp.applicationDomainConfig.home.notFound">
                No Application Domain Configs found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ApplicationDomainConfig;
