import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { Translate, TextFormat } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./project-status.reducer";

export const ProjectStatusDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const projectStatusEntity = useAppSelector(
    (state) => state.projectStatus.entity
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="projectStatusDetailsHeading">
          <Translate contentKey="CorpvineApp.projectStatus.detail.title">
            ProjectStatus
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{projectStatusEntity.id}</dd>
          <dt>
            <span id="label">
              <Translate contentKey="CorpvineApp.projectStatus.label">
                Label
              </Translate>
            </span>
          </dt>
          <dd>{projectStatusEntity.label}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="CorpvineApp.projectStatus.description">
                Description
              </Translate>
            </span>
          </dt>
          <dd>{projectStatusEntity.description}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="CorpvineApp.projectStatus.isActive">
                Is Active
              </Translate>
            </span>
          </dt>
          <dd>{projectStatusEntity.isActive}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="CorpvineApp.projectStatus.createdBy">
                Created By
              </Translate>
            </span>
          </dt>
          <dd>{projectStatusEntity.createdBy}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="CorpvineApp.projectStatus.modifiedBy">
                Modified By
              </Translate>
            </span>
          </dt>
          <dd>{projectStatusEntity.modifiedBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="CorpvineApp.projectStatus.createdAt">
                Created At
              </Translate>
            </span>
          </dt>
          <dd>
            {projectStatusEntity.createdAt ? (
              <TextFormat
                value={projectStatusEntity.createdAt}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="CorpvineApp.projectStatus.modifiedAt">
                Modified At
              </Translate>
            </span>
          </dt>
          <dd>
            {projectStatusEntity.modifiedAt ? (
              <TextFormat
                value={projectStatusEntity.modifiedAt}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
        </dl>
        <Button
          tag={Link}
          to="/project-status"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/project-status/${projectStatusEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProjectStatusDetail;
