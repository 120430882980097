import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import ApplicationDomainConfig from "./application-domain-config";
import ApplicationDomainConfigDetail from "./application-domain-config-detail";
import ApplicationDomainConfigUpdate from "./application-domain-config-update";
import ApplicationDomainConfigDeleteDialog from "./application-domain-config-delete-dialog";

const ApplicationDomainConfigRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ApplicationDomainConfig />} />
    <Route path="new" element={<ApplicationDomainConfigUpdate />} />
    <Route path=":id">
      <Route index element={<ApplicationDomainConfigDetail />} />
      <Route path="edit" element={<ApplicationDomainConfigUpdate />} />
      <Route path="delete" element={<ApplicationDomainConfigDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApplicationDomainConfigRoutes;
