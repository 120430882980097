import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import {
  Translate,
  TextFormat,
  getSortState,
  JhiPagination,
  JhiItemCount,
} from "react-jhipster"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import {
  ASC,
  DESC,
  ITEMS_PER_PAGE,
  SORT,
} from "app/shared/util/pagination.constants";
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { IPrvnKpi } from "app/shared/model/prvn-kpi.model";
import { getEntities } from "./prvn-kpi.reducer";

export const PrvnKpi = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      getSortState(location, ITEMS_PER_PAGE, "id"),
      location.search
    )
  );

  const prvnKpiList = useAppSelector((state) => state.prvnKpi.entities);
  const loading = useAppSelector((state) => state.prvnKpi.loading);
  const totalItems = useAppSelector((state) => state.prvnKpi.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(",");
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = (p) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = (currentPage) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="prvn-kpi-heading" data-cy="PrvnKpiHeading">
        <Translate contentKey="CorpvineApp.prvnKpi.home.title">
          Prvn Kpis
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{" "}
            <Translate contentKey="CorpvineApp.prvnKpi.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/prvn-kpi/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="CorpvineApp.prvnKpi.home.createLabel">
              Create new Prvn Kpi
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {prvnKpiList && prvnKpiList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort("id")}>
                  <Translate contentKey="CorpvineApp.prvnKpi.id">ID</Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("name")}>
                  <Translate contentKey="CorpvineApp.prvnKpi.name">
                    Name
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("description")}>
                  <Translate contentKey="CorpvineApp.prvnKpi.description">
                    Description
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("isactive")}>
                  <Translate contentKey="CorpvineApp.prvnKpi.isactive">
                    Isactive
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th >
                  <Translate contentKey="CorpvineApp.prvnKpi.kpi_type">
                    Kpi Type
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th >
                  <Translate contentKey="CorpvineApp.prvnKpi.level">
                    Level
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th >
                  <Translate contentKey="CorpvineApp.prvnKpi.kpi_order">
                    Kpi Order
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th >
                  <Translate contentKey="CorpvineApp.prvnKpi.audit_period">
                    Audit Period
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th >
                  <Translate contentKey="CorpvineApp.prvnKpi.parent_id">
                    Parent Id
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th >
                  <Translate contentKey="CorpvineApp.prvnKpi.max_score">
                    Max Score
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
<th>
                  <Translate contentKey="CorpvineApp.prvnKpi.created_by">
                    Created By
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th >
                  <Translate contentKey="CorpvineApp.prvnKpi.created_date">
                    Created Date
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
<th>
                  <Translate contentKey="CorpvineApp.prvnKpi.modified_by">
                    Modified By
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th >
                  <Translate contentKey="CorpvineApp.prvnKpi.modified_date">
                    Modified Date
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                                <th />
              </tr>
            </thead>
            <tbody>
              {prvnKpiList.map((prvnKpi, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/prvn-kpi/${prvnKpi.id}`}
                      color="link"
                      size="sm"
                    >
                      {prvnKpi.id}
                    </Button>
                  </td>
                  <td>{prvnKpi.name}</td>
                  <td>{prvnKpi.description}</td>
                  <td>{prvnKpi.isactive}</td>
                  <td>
                    <Translate
                      contentKey={`CorpvineApp.kpitype.${prvnKpi.kpi_type}`}
                    />
                  </td>
                  <td>{prvnKpi.level}</td>
                  <td>{prvnKpi.kpi_order}</td>
                  <td>
                    <Translate
                      contentKey={`CorpvineApp.auditperiod.${prvnKpi.audit_period}`}
                    />
                  </td>
                  <td>{prvnKpi.parent_id}</td>
                  <td>{prvnKpi.max_score}</td>
<td>
                    {prvnKpi.createdBy ? (prvnKpi.createdBy) : (
                      ""
                    )}
                  </td>
                  <td>
                    {prvnKpi.created_date ? (
                      <TextFormat
                        type="date"
                        value={prvnKpi.created_date}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
<td>
                    {prvnKpi.modifiedBy ? (prvnKpi.modifiedBy) : (
                      ""
                    )}
                  </td>
                  <td>
                    {prvnKpi.modified_date ? (
                      <TextFormat
                        type="date"
                        value={prvnKpi.modified_date}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                                    <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/prvn-kpi/${prvnKpi.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/prvn-kpi/${prvnKpi.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/prvn-kpi/${prvnKpi.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="CorpvineApp.prvnKpi.home.notFound">
                No Prvn Kpis found
              </Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={prvnKpiList && prvnKpiList.length > 0 ? "" : "d-none"}>
          <div className="justify-content-center d-flex">
            <JhiItemCount
              page={paginationState.activePage}
              total={totalItems}
              itemsPerPage={paginationState.itemsPerPage}
              i18nEnabled
            />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PrvnKpi;
