import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import {
  Translate,
  ValidatedField,
  ValidatedForm,
  translate,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch, useAppSelector } from "app/config/store";

import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from "./application-domain-config.reducer";

export const ApplicationDomainConfigUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const applicationDomainConfigEntity = useAppSelector(
    (state) => state.applicationDomainConfig.entity,
  );
  const loading = useAppSelector(
    (state) => state.applicationDomainConfig.loading,
  );
  const updating = useAppSelector(
    (state) => state.applicationDomainConfig.updating,
  );
  const updateSuccess = useAppSelector(
    (state) => state.applicationDomainConfig.updateSuccess,
  );

  const handleClose = () => {
    navigate("/application-domain-config");
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    if (values.id !== undefined && typeof values.id !== "number") {
      values.id = Number(values.id);
    }

    const entity = {
      ...applicationDomainConfigEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...applicationDomainConfigEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.applicationDomainConfig.home.createOrEditLabel"
            data-cy="ApplicationDomainConfigCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.applicationDomainConfig.home.createOrEditLabel">
              Create or edit a ApplicationDomainConfig
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="application-domain-config-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate(
                  "CorpvineApp.applicationDomainConfig.config_key",
                )}
                id="application-domain-config-config_key"
                name="config_key"
                data-cy="config_key"
                type="text"
              />
              <ValidatedField
                label={translate(
                  "CorpvineApp.applicationDomainConfig.config_value",
                )}
                id="application-domain-config-config_value"
                name="config_value"
                data-cy="config_value"
                type="text"
              />
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/application-domain-config"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationDomainConfigUpdate;
