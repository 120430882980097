import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Row, Col, FormText } from "reactstrap";
import {
  isNumber,
  Translate,
  translate,
  ValidatedField,
  ValidatedForm,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { mapIdList } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { ISuUser } from "app/shared/model/su-user.model";
import { getEntities as getSuUsers } from "app/entities/su-user/su-user.reducer";
import { IProjectMemberClientRoles } from "app/shared/model/project-member-client-roles.model";
import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
} from "./project-member-client-roles.reducer";
import { DROP_DOWN_LIMIT } from "app/shared/util/pagination.constants";

export const ProjectMemberClientRolesUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const suUsers = useAppSelector((state) => state.suUser.entities);
  const projectMemberClientRolesEntity = useAppSelector(
    (state) => state.projectMemberClientRoles.entity
  );
  const loading = useAppSelector(
    (state) => state.projectMemberClientRoles.loading
  );
  const updating = useAppSelector(
    (state) => state.projectMemberClientRoles.updating
  );
  const updateSuccess = useAppSelector(
    (state) => state.projectMemberClientRoles.updateSuccess
  );

  const handleClose = () => {
    navigate("/project-member-client-roles" + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getSuUsers({size:DROP_DOWN_LIMIT,
      sort: 'login,asc'}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    values.created_at = convertDateTimeToServer(values.created_at);
    values.updated_at = convertDateTimeToServer(values.updated_at);

    const entity = {
      ...projectMemberClientRolesEntity,
      ...values,
       modifiedBy: isNew
            ? values.createdBy // New entity: use createdBy
            : values.modifiedBy, // Existing entity: use modified_by,

    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          created_at: displayDefaultDateTime(),
          updated_at: displayDefaultDateTime(),
        }
      : {
          ...projectMemberClientRolesEntity,
          created_at: convertDateTimeFromServer(
            projectMemberClientRolesEntity.created_at
          ),
          updated_at: displayDefaultDateTime(),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.projectMemberClientRoles.home.createOrEditLabel"
            data-cy="ProjectMemberClientRolesCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.projectMemberClientRoles.home.createOrEditLabel">
              Create or edit a ProjectMemberClientRoles
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="project-member-client-roles-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate("CorpvineApp.projectMemberClientRoles.name")}
                id="project-member-client-roles-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                  maxLength: {
                    value: 255,
                    message: translate("entity.validation.maxlength", {
                      max: 255,
                    }),
                  },
                }}
              />
              <ValidatedField
                label={translate(
                  "CorpvineApp.projectMemberClientRoles.description"
                )}
                id="project-member-client-roles-description"
                name="description"
                data-cy="description"
                type="text"
                validate={{
                  maxLength: {
                    value: 255,
                    message: translate("entity.validation.maxlength", {
                      max: 255,
                    }),
                  },
                }}
              />
              <ValidatedField
                label={translate("CorpvineApp.projectMemberClientRoles.active")}
                id="project-member-client-roles-active"
                name="active"
                data-cy="active"
                placeholder="1-Active or 0-Inactive"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                  min: {
                    value: 0,
                    message: translate("entity.validation.min", { min: 0 }),
                  },
                  max: {
                    value: 1,
                    message: translate("entity.validation.max", { max: 1 }),
                  },
                  validate: (v) =>
                    isNumber(v) || translate("entity.validation.number"),
                }}
              />
              {isNew
              ? (
              <ValidatedField
                id="project-member-client-roles-created_by"
                name="createdBy"
                data-cy="createdBy"
                label={translate(
                  "CorpvineApp.projectMemberClientRoles.created_by"
                )}
                type="text"
                required
              />

              ) : null 
            }
              {!isNew
              ? (
              <ValidatedField
                id="project-member-client-roles-modified_by"
                name="modifiedBy"
                data-cy="modifiedBy"
                label={translate(
                  "CorpvineApp.projectMemberClientRoles.modified_by"
                )}
                type="text"
                required
              />

               ) : null 
              }
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/project-member-client-roles"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProjectMemberClientRolesUpdate;
