import dayjs from "dayjs";

export interface IProjectStatus {
  id?: number;
  label?: string | null;
  description?: string | null;
  isActive?: number | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
  modifiedAt?: string | null;
}

export const defaultValue: Readonly<IProjectStatus> = {};
