import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import EntitiesRoutes from 'app/entities/routes';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import PrvnKpi from './entities/prvn-kpi';
import SkillCategory from './entities/skill-category';
import Skill from './entities/skill';
import CareerGrowthGoal from './entities/career-growth-goal';
import SkillCategorySkillLink from './entities/skill-category-skill-link';
import TitlePositionLink from './entities/title-position-link';
import MainJobTitles from './entities/main-job-titles';
import MainPositions from './entities/main-positions';
import ProjectMemberSysvineRoles from './entities/project-member-sysvine-roles';
import ProjectMemberClientRoles from './entities/project-member-client-roles';
import ProjectMemberCostTypes from './entities/project-member-cost-types';
import ProjectMemberStatuses from './entities/project-member-statuses';
import Location from './entities/location';
import Category from './entities/category';
import Source from './entities/source';
import Review from './entities/review';
import ApplicationDomainConfig from './entities/application-domain-config';




const loading = <div>loading ...</div>;

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route index element={<Home />} />
        <Route path="prvn-kpi/*" element={<PrvnKpi />} />
        <Route path="skill-category/*" element={<SkillCategory />} />
        <Route path="skill/*" element={<Skill  />} />
        <Route path="career-growth-goal/*" element={<CareerGrowthGoal  />} />
        <Route path="Skill-category-skill-link/*" element={<SkillCategorySkillLink  />} />
        <Route path="main-job-titles/*" element={<MainJobTitles  />} />
        <Route path="main-positions/*" element={<MainPositions  />} />
        <Route path="project-member-sysvine-roles/*" element={<ProjectMemberSysvineRoles  />} />
        <Route path="project-member-client-roles/*" element={<ProjectMemberClientRoles  />} />
        <Route path="project-member-cost-types/*" element={<ProjectMemberCostTypes  />} />
        <Route path="project-member-statuses/*" element={<ProjectMemberStatuses  />} />
        <Route path="location/*" element={<Location  />} />
        <Route path="category/*" element={<Category  />} />
        <Route path="source/*" element={<Source  />} />
         <Route path="review/*" element={<Review  />} />

         <Route path="application-domain-config/*" element={<ApplicationDomainConfig  />} />

        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="account">
          <Route
            path="*"
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route path="register" element={<Register />} />
          <Route path="activate" element={<Activate />} />
          <Route path="reset">
            <Route path="request" element={<PasswordResetInit />} />
            <Route path="finish" element={<PasswordResetFinish />} />
          </Route>
        </Route>
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              {/* <EntitiesRoutes /> */}
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
