import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { Translate, TextFormat } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./prvn-kpi.reducer";

export const PrvnKpiDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const prvnKpiEntity = useAppSelector((state) => state.prvnKpi.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="prvnKpiDetailsHeading">
          <Translate contentKey="CorpvineApp.prvnKpi.detail.title">
            PrvnKpi
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{prvnKpiEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="CorpvineApp.prvnKpi.name">Name</Translate>
            </span>
          </dt>
          <dd>{prvnKpiEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="CorpvineApp.prvnKpi.description">
                Description
              </Translate>
            </span>
          </dt>
          <dd>{prvnKpiEntity.description}</dd>
          <dt>
            <span id="isactive">
              <Translate contentKey="CorpvineApp.prvnKpi.isactive">
                Isactive
              </Translate>
            </span>
          </dt>
          <dd>{prvnKpiEntity.isactive}</dd>
          <dt>
            <span id="kpi_type">
              <Translate contentKey="CorpvineApp.prvnKpi.kpi_type">
                Kpi Type
              </Translate>
            </span>
          </dt>
          <dd>{prvnKpiEntity.kpi_type}</dd>
          <dt>
            <span id="level">
              <Translate contentKey="CorpvineApp.prvnKpi.level">
                Level
              </Translate>
            </span>
          </dt>
          <dd>{prvnKpiEntity.level}</dd>
          <dt>
            <span id="kpi_order">
              <Translate contentKey="CorpvineApp.prvnKpi.kpi_order">
                Kpi Order
              </Translate>
            </span>
          </dt>
          <dd>{prvnKpiEntity.kpi_order}</dd>
          <dt>
            <span id="audit_period">
              <Translate contentKey="CorpvineApp.prvnKpi.audit_period">
                Audit Period
              </Translate>
            </span>
          </dt>
          <dd>{prvnKpiEntity.audit_period}</dd>
          <dt>
            <span id="parent_id">
              <Translate contentKey="CorpvineApp.prvnKpi.parent_id">
                Parent Id
              </Translate>
            </span>
          </dt>
          <dd>{prvnKpiEntity.parent_id}</dd>
          <dt>
            <span id="max_score">
              <Translate contentKey="CorpvineApp.prvnKpi.max_score">
                Max Score
              </Translate>
            </span>
          </dt>
          <dd>{prvnKpiEntity.max_score}</dd>
<dt>
            <Translate contentKey="CorpvineApp.prvnKpi.created_by">
              Created By
            </Translate>
          </dt>
          <dd>
            {prvnKpiEntity.createdBy ? prvnKpiEntity.createdBy : ""}

          </dd>
          <dt>
            <span id="created_date">
              <Translate contentKey="CorpvineApp.prvnKpi.created_date">
                Created Date
              </Translate>
            </span>
          </dt>
          <dd>
            {prvnKpiEntity.created_date ? (
              <TextFormat
                value={prvnKpiEntity.created_date}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
<dt>
            <Translate contentKey="CorpvineApp.prvnKpi.modified_by">
              Modified By
            </Translate>
          </dt>
          <dd>
            {prvnKpiEntity.modifiedBy ? prvnKpiEntity.modifiedBy : ""}
          </dd>
          <dt>
            <span id="modified_date">
              <Translate contentKey="CorpvineApp.prvnKpi.modified_date">
                Modified Date
              </Translate>
            </span>
          </dt>
          <dd>
            {prvnKpiEntity.modified_date ? (
              <TextFormat
                value={prvnKpiEntity.modified_date}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
                  </dl>
        <Button
          tag={Link}
          to="/prvn-kpi"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/prvn-kpi/${prvnKpiEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PrvnKpiDetail;
