import dayjs from "dayjs";
import { ISuUser } from "app/shared/model/su-user.model";

export interface IProjectMemberSysvineRoles {
  id?: number;
  name?: string;
  description?: string | null;
  active?: number;
  created_at?: string;
  updated_at?: string;
  createdBy?: ISuUser;
  modifiedBy?: ISuUser;
}

export const defaultValue: Readonly<IProjectMemberSysvineRoles> = {};
