import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import ProjectStatus from "./project-status";
import ProjectStatusDetail from "./project-status-detail";
import ProjectStatusUpdate from "./project-status-update";
import ProjectStatusDeleteDialog from "./project-status-delete-dialog";

const ProjectStatusRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProjectStatus />} />
    <Route path="new" element={<ProjectStatusUpdate />} />
    <Route path=":id">
      <Route index element={<ProjectStatusDetail />} />
      <Route path="edit" element={<ProjectStatusUpdate />} />
      <Route path="delete" element={<ProjectStatusDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProjectStatusRoutes;
