import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Row, Col, FormText } from "reactstrap";
import { DROP_DOWN_LIMIT } from "app/shared/util/pagination.constants";
import {
  isNumber,
  Translate,
  translate,
  ValidatedField,
  ValidatedForm,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { mapIdList } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { ISuUser } from "app/shared/model/su-user.model";
import { getEntities as getSuUsers } from "app/entities/su-user/su-user.reducer";
import { ISkill } from "app/shared/model/skill.model";
import { getEntity, updateEntity, createEntity, reset } from "./skill.reducer";

export const SkillUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const suUsers = useAppSelector((state) => state.suUser.entities);
  const skillEntity = useAppSelector((state) => state.skill.entity);
  const loading = useAppSelector((state) => state.skill.loading);
  const updating = useAppSelector((state) => state.skill.updating);
  const updateSuccess = useAppSelector((state) => state.skill.updateSuccess);

  const handleClose = () => {
    navigate("/skill" + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getSuUsers({size:DROP_DOWN_LIMIT,
      sort: 'login,asc'}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {

      console.log("Selected Created By:", values.createdBy);
      console.log("Selected Created By:", values.modifiedBy);
    values.createddate = convertDateTimeToServer(values.createddate);
    values.modifieddate = convertDateTimeToServer(values.modifieddate);

    const entity = {
      ...skillEntity,
      ...values,
            modified_by: isNew
                 ? values.createdBy // New entity: use createdBy
                 : values.modifiedBy, // Existing entity: use modified_by,

    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createddate: displayDefaultDateTime(),
          modifieddate: displayDefaultDateTime(),
        }
      : {
          ...skillEntity,
          createddate: convertDateTimeFromServer(skillEntity.createddate),
          modifieddate: displayDefaultDateTime(),
          createdby: skillEntity?.createdBy?.id,
          // modifiedby: skillEntity?.modifiedby?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.skill.home.createOrEditLabel"
            data-cy="SkillCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.skill.home.createOrEditLabel">
              Create or edit a Skill
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="skill-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate("CorpvineApp.skill.name")}
                id="skill-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                }}
              />
              <ValidatedField
                label={translate("CorpvineApp.skill.description")}
                id="skill-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.skill.isactive")}
                id="skill-isactive"
                placeholder="1-Active or 0-Inactive"
                name="isactive"
                data-cy="isactive"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                  min: {
                    value: 0,
                    message: translate("entity.validation.min", { min: 0 }),
                  },
                  max: {
                    value: 1,
                    message: translate("entity.validation.max", { max: 1 }),
                  },
                  validate: (v) =>
                    isNumber(v) || translate("entity.validation.number"),
                }}
              />
              <ValidatedField
                label={translate("CorpvineApp.skill.link")}
                id="skill-link"
                name="link"
                data-cy="link"
                type="text"
              />
               {isNew
              ? (
              <ValidatedField
                id="skill-createdby"
                name="createdBy"
                data-cy="createdBy"
                label={translate("CorpvineApp.skill.createdby")}
                type="text"
                required
              />

              : null}

              {!isNew
              ? (
              <ValidatedField
                id="skill-modifiedby"
                name="modifiedBy"
                data-cy="modifiedBy"
                label={translate("CorpvineApp.skill.modifiedby")}
                type="text"
                required
             />
              : null}
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/skill"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SkillUpdate;
