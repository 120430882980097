import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { TextFormat, Translate } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./division.reducer";

export const DivisionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const divisionEntity = useAppSelector((state) => state.division.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="divisionDetailsHeading">
          <Translate contentKey="CorpvineApp.division.detail.title">
            Division
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{divisionEntity.id}</dd>
          <dt>
          <Translate contentKey="CorpvineApp.division.organisation">
            Organisation
          </Translate>
        </dt>
        <dd>
          {divisionEntity.organisation ? divisionEntity.organisation.org_code : ""}
        </dd>
          <dt>
            <span id="divisionCode">
              <Translate contentKey="CorpvineApp.division.divisionCode">
                Division Code
              </Translate>
            </span>
          </dt>
          <dd>{divisionEntity.divisionCode}</dd>
          <dt>
            <span id="divisionName">
              <Translate contentKey="CorpvineApp.division.divisionName">
                Division Name
              </Translate>
            </span>
          </dt>
          <dd>{divisionEntity.divisionName}</dd>
          <dt>
            <span id="divisionDescription">
              <Translate contentKey="CorpvineApp.division.divisionDescription">
                Division Description
              </Translate>
            </span>
          </dt>
          <dd>{divisionEntity.divisionDescription}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="CorpvineApp.division.isActive">
                Is Active
              </Translate>
            </span>
          </dt>
          <dd>{divisionEntity.isActive}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="CorpvineApp.division.createdBy">
                Created By
              </Translate>
            </span>
          </dt>
          <dd>{divisionEntity.createdBy}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="CorpvineApp.division.modifiedBy">
                Modified By
              </Translate>
            </span>
          </dt>
          <dd>{divisionEntity.modifiedBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="CorpvineApp.division.createdDate">
                Created Date
              </Translate>
            </span>
          </dt>
          <dd>
            {divisionEntity.createdDate ? (
              <TextFormat
                value={divisionEntity.createdDate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedDate">
              <Translate contentKey="CorpvineApp.division.modifiedDate">
                Modified Date
              </Translate>
            </span>
          </dt>
          <dd>
            {divisionEntity.modifiedDate ? (
              <TextFormat
                value={divisionEntity.modifiedDate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>

        </dl>
        <Button
          tag={Link}
          to="/division"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/division/${divisionEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DivisionDetail;
