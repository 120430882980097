import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { Translate, TextFormat } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { IProjectStatus } from "app/shared/model/project-status.model";
import { getEntities } from "./project-status.reducer";

export const ProjectStatus = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const projectStatusList = useAppSelector(
    (state) => state.projectStatus.entities
  );
  const loading = useAppSelector((state) => state.projectStatus.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  return (
    <div>
      <h2 id="project-status-heading" data-cy="ProjectStatusHeading">
        <Translate contentKey="CorpvineApp.projectStatus.home.title">
          Project Statuses
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{" "}
            <Translate contentKey="CorpvineApp.projectStatus.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/project-status/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="CorpvineApp.projectStatus.home.createLabel">
              Create new Project Status
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {projectStatusList && projectStatusList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="CorpvineApp.projectStatus.id">
                    ID
                  </Translate>
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.projectStatus.label">
                    Label
                  </Translate>
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.projectStatus.description">
                    Description
                  </Translate>
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.projectStatus.isActive">
                    Is Active
                  </Translate>
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.projectStatus.createdBy">
                    Created By
                  </Translate>
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.projectStatus.modifiedBy">
                    Modified By
                  </Translate>
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.projectStatus.createdAt">
                    Created At
                  </Translate>
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.projectStatus.modifiedAt">
                    Modified At
                  </Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {projectStatusList.map((projectStatus, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/project-status/${projectStatus.id}`}
                      color="link"
                      size="sm"
                    >
                      {projectStatus.id}
                    </Button>
                  </td>
                  <td>{projectStatus.label}</td>
                  <td>{projectStatus.description}</td>
                  <td>{projectStatus.isActive}</td>
                  <td>{projectStatus.createdBy}</td>
                  <td>{projectStatus.modifiedBy}</td>
                  <td>
                    {projectStatus.createdAt ? (
                      <TextFormat
                        type="date"
                        value={projectStatus.createdAt}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td>
                    {projectStatus.modifiedAt ? (
                      <TextFormat
                        type="date"
                        value={projectStatus.modifiedAt}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/project-status/${projectStatus.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/project-status/${projectStatus.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/project-status/${projectStatus.id}/delete`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="CorpvineApp.projectStatus.home.notFound">
                No Project Statuses found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ProjectStatus;
