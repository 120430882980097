import dayjs from "dayjs";
import { IOrganisation } from "app/shared/model/organisation.model";

export interface IDivision {
  id?: number;
  divisionCode?: string | null;
  divisionName?: string | null;
  divisionDescription?: string | null;
  isActive?: number;
  createdBy?: string | null;
  modifiedBy?: string | null;
  createdDate?: dayjs.Dayjs | null;
  modifiedDate?: dayjs.Dayjs | null;
  organisation?: IOrganisation | null;
}

export const defaultValue: Readonly<IDivision> = {};
