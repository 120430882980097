import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { Translate, TextFormat } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./skill.reducer";

export const SkillDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const skillEntity = useAppSelector((state) => state.skill.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="skillDetailsHeading">
          <Translate contentKey="CorpvineApp.skill.detail.title">
            Skill
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{skillEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="CorpvineApp.skill.name">Name</Translate>
            </span>
          </dt>
          <dd>{skillEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="CorpvineApp.skill.description">
                Description
              </Translate>
            </span>
          </dt>
          <dd>{skillEntity.description}</dd>
          <dt>
            <span id="isactive">
              <Translate contentKey="CorpvineApp.skill.isactive">
                Isactive
              </Translate>
            </span>
          </dt>
          <dd>{skillEntity.isactive}</dd>
          <dt>
            <span id="link">
              <Translate contentKey="CorpvineApp.skill.link">Link</Translate>
            </span>
          </dt>
          <dd>{skillEntity.link}</dd>
          <dt>
            <Translate contentKey="CorpvineApp.skill.createdby">
              Createdby
            </Translate>
          </dt>
          <dd>{skillEntity.createdBy ? skillEntity.createdBy : ""}</dd>
          <dt>
            <span id="createddate">
              <Translate contentKey="CorpvineApp.skill.createddate">
                Createddate
              </Translate>
            </span>
          </dt>
          <dd>
            {skillEntity.createddate ? (
              <TextFormat
                value={skillEntity.createddate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.skill.modifiedby">
              Modifiedby
            </Translate>
          </dt>
          <dd>{skillEntity.modifiedBy ? skillEntity.modifiedBy : ""}</dd>
          <dt>
            <span id="modifieddate">
              <Translate contentKey="CorpvineApp.skill.modifieddate">
                Modifieddate
              </Translate>
            </span>
          </dt>
          <dd>
            {skillEntity.modifieddate ? (
              <TextFormat
                value={skillEntity.modifieddate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
       </dl>
        <Button
          tag={Link}
          to="/skill"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/skill/${skillEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SkillDetail;
