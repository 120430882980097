import dayjs from "dayjs";
import { ISuUser } from "app/shared/model/su-user.model";

export interface ISkill {
  id?: number;
  name?: string;
  description?: string | null;
  isactive?: number;
  link?: string | null;
  createddate?: string;
  modifieddate?: string;
  createdBy?: ISuUser;
  modifiedBy?: ISuUser;
}

export const defaultValue: Readonly<ISkill> = {};
