import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import {
  Translate,
  ValidatedField,
  ValidatedForm,
  isNumber,
  translate,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntities as getOrganisations } from "app/entities/organisation/organisation.reducer";
import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from "./division.reducer";

export const DivisionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const organisations = useAppSelector((state) => state.organisation.entities);
  const divisionEntity = useAppSelector((state) => state.division.entity);
  const loading = useAppSelector((state) => state.division.loading);
  const updating = useAppSelector((state) => state.division.updating);
  const updateSuccess = useAppSelector((state) => state.division.updateSuccess);

  const handleClose = () => {
    navigate("/division");
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getOrganisations({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    if (values.id !== undefined && typeof values.id !== "number") {
      values.id = Number(values.id);
    }
    if (values.isActive !== undefined && typeof values.isActive !== "number") {
      values.isActive = Number(values.isActive);
    }
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.modifiedDate = convertDateTimeToServer(values.modifiedDate);

    const entity = {
      ...divisionEntity,
      ...values,
      organisation: organisations.find(
        (it) => it.id.toString() === values.organisation?.toString(),
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          modifiedDate: displayDefaultDateTime(),
        }
      : {
          ...divisionEntity,
          createdDate: convertDateTimeFromServer(divisionEntity.createdDate),
          modifiedDate: displayDefaultDateTime(),
          organisation: divisionEntity?.organisation?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.division.home.createOrEditLabel"
            data-cy="DivisionCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.division.home.createOrEditLabel">
              Create or edit a Division
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="division-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
                <ValidatedField
                          id="division-organisation"
                          name="organisation"
                          data-cy="organisation"
                          label={translate("CorpvineApp.division.organisation")}
                          type="select"
                          required
                        >
                          <option value="" key="0" />
                          {organisations
                            ? organisations.map((otherEntity) => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.org_code}
                                </option>
                              ))
                            : null}
                        </ValidatedField>
              <ValidatedField
                label={translate("CorpvineApp.division.divisionCode")}
                id="division-divisionCode"
                name="divisionCode"
                data-cy="divisionCode"
                type="text"
                required
              />
              <ValidatedField
                label={translate("CorpvineApp.division.divisionName")}
                id="division-divisionName"
                name="divisionName"
                data-cy="divisionName"
                type="text"
                required
              />
              <ValidatedField
                label={translate("CorpvineApp.division.divisionDescription")}
                id="division-divisionDescription"
                name="divisionDescription"
                data-cy="divisionDescription"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.division.isActive")}
                id="division-isActive"
                name="isActive"
                data-cy="isActive"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                  min: {
                    value: 0,
                    message: translate("entity.validation.min", { min: 0 }),
                  },
                  max: {
                    value: 1,
                    message: translate("entity.validation.max", { max: 1 }),
                  },
                  validate: (v) =>
                    isNumber(v) || translate("entity.validation.number"),
                }}
              />
              {isNew
                  ?(
              <ValidatedField
                label={translate("CorpvineApp.division.createdBy")}
                id="division-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
                required
              />
              :null
              }
            {!isNew
                ?(
              <ValidatedField
                label={translate("CorpvineApp.division.modifiedBy")}
                id="division-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
                required
              />
              )
          :null
          }
              {/*<ValidatedField
                label={translate("CorpvineApp.division.createdDate")}
                id="division-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate("CorpvineApp.division.modifiedDate")}
                id="division-modifiedDate"
                name="modifiedDate"
                data-cy="modifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />*/}

              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/division"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DivisionUpdate;
