import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { Translate, TextFormat } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./skill-category.reducer";

export const SkillCategoryDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const skillCategoryEntity = useAppSelector(
    (state) => state.skillCategory.entity
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="skillCategoryDetailsHeading">
          <Translate contentKey="CorpvineApp.skillCategory.detail.title">
            SkillCategory
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{skillCategoryEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="CorpvineApp.skillCategory.name">
                Name
              </Translate>
            </span>
          </dt>
          <dd>{skillCategoryEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="CorpvineApp.skillCategory.description">
                Description
              </Translate>
            </span>
          </dt>
          <dd>{skillCategoryEntity.description}</dd>
          <dt>
            <span id="isactive">
              <Translate contentKey="CorpvineApp.skillCategory.isactive">
                Isactive
              </Translate>
            </span>
          </dt>
          <dd>{skillCategoryEntity.isactive}</dd>
          <dt>
            <Translate contentKey="CorpvineApp.skillCategory.createdby">
              Createdby
            </Translate>
          </dt>
          <dd>
            {skillCategoryEntity.createdBy
              ? skillCategoryEntity.createdBy
              : ""}
          </dd>
          <dt>
            <span id="createddate">
              <Translate contentKey="CorpvineApp.skillCategory.createddate">
                Createddate
              </Translate>
            </span>
          </dt>
          <dd>
            {skillCategoryEntity.createddate ? (
              <TextFormat
                value={skillCategoryEntity.createddate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.skillCategory.modifiedby">
              Modifiedby
            </Translate>
          </dt>
          <dd>
            {skillCategoryEntity.modifiedBy
              ? skillCategoryEntity.modifiedBy
              : ""}
          </dd>
          <dt>
            <span id="modifieddate">
              <Translate contentKey="CorpvineApp.skillCategory.modifieddate">
                Modifieddate
              </Translate>
            </span>
          </dt>
          <dd>
            {skillCategoryEntity.modifieddate ? (
              <TextFormat
                value={skillCategoryEntity.modifieddate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
        </dl>
        <Button
          tag={Link}
          to="/skill-category"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/skill-category/${skillCategoryEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SkillCategoryDetail;
