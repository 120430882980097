import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { Translate, TextFormat } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./project-member-sysvine-roles.reducer";

export const ProjectMemberSysvineRolesDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const projectMemberSysvineRolesEntity = useAppSelector(
    (state) => state.projectMemberSysvineRoles.entity
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="projectMemberSysvineRolesDetailsHeading">
          <Translate contentKey="CorpvineApp.projectMemberSysvineRoles.detail.title">
            ProjectMemberSysvineRoles
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{projectMemberSysvineRolesEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="CorpvineApp.projectMemberSysvineRoles.name">
                Name
              </Translate>
            </span>
          </dt>
          <dd>{projectMemberSysvineRolesEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="CorpvineApp.projectMemberSysvineRoles.description">
                Description
              </Translate>
            </span>
          </dt>
          <dd>{projectMemberSysvineRolesEntity.description}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="CorpvineApp.projectMemberSysvineRoles.active">
                Active
              </Translate>
            </span>
          </dt>
          <dd>{projectMemberSysvineRolesEntity.active}</dd>
          <dt>
            <Translate contentKey="CorpvineApp.projectMemberSysvineRoles.created_by">
              Created By
            </Translate>
          </dt>
          <dd>
            {projectMemberSysvineRolesEntity.createdBy
              ? projectMemberSysvineRolesEntity.createdBy
              : ""}
          </dd>
          <dt>
            <span id="created_at">
              <Translate contentKey="CorpvineApp.projectMemberSysvineRoles.created_at">
                Created At
              </Translate>
            </span>
          </dt>
          <dd>
            {projectMemberSysvineRolesEntity.created_at ? (
              <TextFormat
                value={projectMemberSysvineRolesEntity.created_at}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.projectMemberSysvineRoles.modified_by">
              Modified By
            </Translate>
          </dt>
          <dd>
            {projectMemberSysvineRolesEntity.modifiedBy
              ? projectMemberSysvineRolesEntity.modifiedBy
              : ""}
          </dd>
          <dt>
            <span id="updated_at">
              <Translate contentKey="CorpvineApp.projectMemberSysvineRoles.updated_at">
                Updated At
              </Translate>
            </span>
          </dt>
          <dd>
            {projectMemberSysvineRolesEntity.updated_at ? (
              <TextFormat
                value={projectMemberSysvineRolesEntity.updated_at}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
        </dl>
        <Button
          tag={Link}
          to="/project-member-sysvine-roles"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/project-member-sysvine-roles/${projectMemberSysvineRolesEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProjectMemberSysvineRolesDetail;
