import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Row, Col, FormText } from "reactstrap";
import {
  isNumber,
  Translate,
  translate,
  ValidatedField,
  ValidatedForm,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { mapIdList } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { IProjectStatus } from "app/shared/model/project-status.model";
import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
} from "./project-status.reducer";

export const ProjectStatusUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const projectStatusEntity = useAppSelector(
    (state) => state.projectStatus.entity
  );
  const loading = useAppSelector((state) => state.projectStatus.loading);
  const updating = useAppSelector((state) => state.projectStatus.updating);
  const updateSuccess = useAppSelector(
    (state) => state.projectStatus.updateSuccess
  );

  const handleClose = () => {
    navigate("/project-status");
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
       if (values.id !== undefined && typeof values.id !== "number") {
            values.id = Number(values.id);
          }
      if (
            values.isActive !== undefined &&
            typeof values.isActive !== "number"
          ) {
             values.isActive = Number(values.isActive);
                }
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);

    const entity = {
      ...projectStatusEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
        }
      : {
          ...projectStatusEntity,
          createdAt: convertDateTimeFromServer(projectStatusEntity.createdAt),
          modifiedAt: displayDefaultDateTime(
              //projectStatusEntity.modifiedAt),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.projectStatus.home.createOrEditLabel"
            data-cy="ProjectStatusCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.projectStatus.home.createOrEditLabel">
              Create or edit a ProjectStatus
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="project-status-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate("CorpvineApp.projectStatus.label")}
                id="project-status-label"
                name="label"
                data-cy="label"
                type="text"
                required
              />
              <ValidatedField
                label={translate("CorpvineApp.projectStatus.description")}
                id="project-status-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.projectStatus.isActive")}
                id="project-status-isActive"
                name="isActive"
                data-cy="isActive"
                type="text"
                validate={{
                required: {
                     value: true,
                     message: translate("entity.validation.required"),
                },
                      min: {
                        value: 0,
                        message: translate("entity.validation.min", { min: 0 }),
                      },
                      max: {
                        value: 1,
                        message: translate("entity.validation.max", { max: 1 }),
                      },
                      validate: (v) =>
                        isNumber(v) || translate("entity.validation.number"),
                    }}
              />

              <ValidatedField
                label={translate("CorpvineApp.projectStatus.createdBy")}
                id="project-status-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
                required
              />


              <ValidatedField
                label={translate("CorpvineApp.projectStatus.modifiedBy")}
                id="project-status-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
                required
              />

             {/* <ValidatedField
                label={translate("CorpvineApp.projectStatus.createdAt")}
                id="project-status-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate("CorpvineApp.projectStatus.modifiedAt")}
                id="project-status-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />*/}
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/project-status"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProjectStatusUpdate;
