import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { Translate } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./application-domain-config.reducer";

export const ApplicationDomainConfigDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const applicationDomainConfigEntity = useAppSelector(
    (state) => state.applicationDomainConfig.entity,
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="applicationDomainConfigDetailsHeading">
          <Translate contentKey="CorpvineApp.applicationDomainConfig.detail.title">
            ApplicationDomainConfig
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{applicationDomainConfigEntity.id}</dd>
          <dt>
            <span id="config_key">
              <Translate contentKey="CorpvineApp.applicationDomainConfig.config_key">
                Config Key
              </Translate>
            </span>
          </dt>
          <dd>{applicationDomainConfigEntity.config_key}</dd>
          <dt>
            <span id="config_value">
              <Translate contentKey="CorpvineApp.applicationDomainConfig.config_value">
                Config Value
              </Translate>
            </span>
          </dt>
          <dd>{applicationDomainConfigEntity.config_value}</dd>
        </dl>
        <Button
          tag={Link}
          to="/application-domain-config"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/application-domain-config/${applicationDomainConfigEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ApplicationDomainConfigDetail;
